import React, { useState, useEffect } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import {
  Container,
  TextField,
  Button,
  Typography,
  Grid,
  Paper,
  IconButton,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { requestWrapper } from 'lib/api/auth';
import { BASE_URL } from 'lib/api/client';
import { useTranslation } from 'react-i18next';

interface IFormInput {
  apiKey: string;
  roomId: string;
  completionThreshold: number;  // Updated field name
  accessCountThreshold: number;
  notify: boolean;
}

const ChatworkSettings: React.FC = () => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [defaultValues, setDefaultValues] = useState<IFormInput | null>(null);
  const { register, handleSubmit, formState: { errors }, reset, setValue } = useForm<IFormInput>();
  const { t } = useTranslation("global");

  useEffect(() => {
    async function fetchSettings() {
      try {
        const response = await requestWrapper(`${BASE_URL}/api/v1/chatwork_setting`, {
          method: 'GET',
        });
        const parsedResponse = {
          apiKey: response.api_key,
          roomId: response.room_id,
          completionThreshold: response.completion_threshold,  // Updated field
          accessCountThreshold: response.access_count_threshold,
          notify: response.notify,
        }
        reset(parsedResponse)
        setDefaultValues(parsedResponse);
        setValue('apiKey', parsedResponse.apiKey);
        setValue('roomId', parsedResponse.roomId);
        setValue('completionThreshold', parsedResponse.completionThreshold);
        setValue('accessCountThreshold', parsedResponse.accessCountThreshold);
        setValue('notify', parsedResponse.notify);
      } catch (error) {
        console.error('Error fetching ChatWork settings:', error);
      }
    }
    fetchSettings();
  }, [setValue, reset]);

  const onSubmit: SubmitHandler<IFormInput> = async (data) => {
    try {
      await requestWrapper(`${BASE_URL}/api/v1/chatwork_setting`, {
        method: 'POST',
        body: {
          chatwork_settings: {
            api_key: data.apiKey,
            room_id: data.roomId,
            completion_threshold: data.completionThreshold,  // Updated field
            access_count_threshold: data.accessCountThreshold,
            notify: data.notify,
          },
        },
      });

      alert('ChatWork settings saved successfully.');
      setIsEditMode(false);
    } catch (error) {
      console.error('Error saving ChatWork settings:', error);
      alert('An error occurred. Please try again.');
    }
  };

  const handleEditClick = () => {
    setIsEditMode(true);
  };

  return (
    <Container maxWidth="sm">
      <Paper elevation={3} sx={{ p: 4, mt: 4 }}>
        <Typography variant="h5" gutterBottom>
          {t("mainSection.chatwork-setting-title")}
          {!isEditMode && (
            <IconButton onClick={handleEditClick} aria-label="edit">
              <EditIcon />
            </IconButton>
          )}
        </Typography>
        <Typography variant="body2" gutterBottom>
          {t("mainSection.chatwork-setting-desc")}
        </Typography>

        {isEditMode ? (
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  label={t("mainSection.api-key")}
                  fullWidth
                  {...register('apiKey', { required: t("mainSection.require-api-key") })}
                  error={!!errors.apiKey}
                  helperText={errors.apiKey?.message}
                  defaultValue={defaultValues?.apiKey}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  label={t("mainSection.room-id")}
                  fullWidth
                  {...register('roomId', { required: t("mainSection.require-room-id")  })}
                  error={!!errors.roomId}
                  helperText={errors.roomId?.message}
                  defaultValue={defaultValues?.roomId}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  label={t("mainSection.completeness-threshold")}
                  fullWidth
                  type="number"
                  {...register('completionThreshold', {
                    required: t("mainSection.required-completeness-threshold"),
                    min: { value: 0, message: t("mainSection.min-completeness") },
                    max: { value: 100, message:  t("mainSection.max-completeness") },
                  })}
                  error={!!errors.completionThreshold}
                  helperText={errors.completionThreshold?.message}
                  defaultValue={defaultValues?.completionThreshold}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  label={t("mainSection.visits-count-threshold")}
                  type="number"
                  fullWidth
                  {...register('accessCountThreshold', { required: t("mainSection.required-visits-count-threshold") })}
                  error={!!errors.accessCountThreshold}
                  helperText={errors.accessCountThreshold?.message}
                  defaultValue={defaultValues?.accessCountThreshold}
                />
              </Grid>

              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      {...register('notify')}
                      defaultChecked={defaultValues?.notify}
                    />
                  }
                  label={t("mainSection.notify")}
                />
              </Grid>

              <Grid item xs={12}>
                <Button variant="contained" color="primary" type="submit" fullWidth>
                  保存
                </Button>
              </Grid>
            </Grid>
          </form>
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="subtitle1"><strong>{t("mainSection.api-key")}:</strong> <span style={{ color: '#3f51b5' }}>{defaultValues?.apiKey}</span></Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1"><strong>{t("mainSection.room-id")}:</strong> <span style={{ color: '#3f51b5' }}>{defaultValues?.roomId}</span></Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1"><strong>{t("mainSection.completeness-threshold")}:</strong> <span style={{ color: '#3f51b5' }}>{defaultValues?.completionThreshold}%</span></Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1"><strong>{t("mainSection.visits-count-threshold")}:</strong> <span style={{ color: '#3f51b5' }}>{defaultValues?.accessCountThreshold}</span></Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1"><strong>{t("mainSection.notify")}:</strong> <span style={{ color: '#3f51b5' }}>{defaultValues?.notify ? 'はい' : 'いいえ'}</span></Typography>
            </Grid>
          </Grid>
        )}
      </Paper>
    </Container>
  );
};

export default ChatworkSettings;
