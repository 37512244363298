import React, { useEffect, useRef, useState } from 'react';
import { Worker, Viewer, SpecialZoomLevel } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import packageJson from '../../../../package.json';
import { cloudFrontDomain, s3Domain } from 'lib/api/client';

interface PDFViewerProps {
  fileUrl: string;
  onDocumentLoad?: () => void;
  onHover?: (hoveredElementContent: string) => void;
  onPageChange?: (pageNumber: number) => void;
}

const characterMap = {
  isCompressed: true,
  url: "https://unpkg.com/pdfjs-dist@2.16.105/cmaps/",
};

const PDFViewer: React.FC<PDFViewerProps> = ({ 
  fileUrl,
  onDocumentLoad,
  onHover,
  onPageChange
}) => {
    const pdfjsVersion = packageJson.dependencies['pdfjs-dist'];
    const viewerRef = useRef<HTMLDivElement | null>(null);
    const [isDocumentLoaded, setIsDocumentLoaded] = useState(false);

    const handleDocumentLoaded = () => {
      setIsDocumentLoaded(true);
      if (onDocumentLoad) onDocumentLoad();
    };

    const handleViewerHover = (event: MouseEvent) => {
        if (!viewerRef.current) return;

        const hoveredElement = event.target as HTMLElement | null;
        if (!hoveredElement) return;

        const tagName = hoveredElement.tagName.toLowerCase();
        if (tagName === 'span' || tagName === 'img') {
          const content = hoveredElement.textContent
          if (onHover && content) {
            onHover(content);
          }
        }
    };

    const handlePageChanged = (e: any) => {
      if (onPageChange) onPageChange(e.currentPage);
    };

    useEffect(() => {
      if (isDocumentLoaded && viewerRef.current) {
        viewerRef.current.addEventListener('mouseover', handleViewerHover);
        return () => {
          viewerRef.current?.removeEventListener('mouseover', handleViewerHover);
        };
      }
    }, [isDocumentLoaded, onHover]);

    const isMobile = window.innerWidth <= 768;
    const viewerHeight = isMobile ? '250px' : '1024px';

    return (
        <Worker workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjsVersion}/build/pdf.worker.min.js`}>
            <div style={{ 
              height: viewerHeight,
              justifyContent: 'center',
              alignItems: 'center',
             }} ref={viewerRef}>
                <Viewer
                    fileUrl={fileUrl.replace(s3Domain, cloudFrontDomain).split('?')[0]}
                    characterMap={characterMap}
                    onDocumentLoad={handleDocumentLoaded}
                    onPageChange={handlePageChanged}
                    defaultScale={SpecialZoomLevel.PageFit}
                />
            </div>
        </Worker>
    );
};

export default PDFViewer;
