import React, { useState, useEffect } from 'react';
import { Fab, Paper, List, ListItem, TextField, Button, IconButton, Box, Typography, CircularProgress } from '@mui/material';
import ChatIcon from '@mui/icons-material/Chat';
import CloseIcon from '@mui/icons-material/Close';
import { useSearchParams } from 'react-router-dom';
import { BASE_URL } from 'lib/api/client';

interface Message {
  text: string;
  sender: 'system' | 'user';
}

const ChatBot: React.FC = () => {
  const [open, setOpen] = useState(false);
  const [messages, setMessages] = useState<Message[]>([
    { text: 'こんにちは、ページに関してはなでも聞いてください。ただし、画像が多い場合、正常に動作しない可能性がありますので、ご注意ください。', sender: 'system' },
  ]);
  const [input, setInput] = useState('');
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const docuId = searchParams.get('docuId');

  useEffect(() => {
    const timer = setTimeout(() => {
      setOpen(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const handleSendMessage = async () => {
    if (input.trim()) {
      const userMessage: Message = { text: input, sender: 'user' };
      setMessages([...messages, userMessage]);
      setInput('');
      setLoading(true);

      try {
        const response = await fetch(`${BASE_URL}/api/v1/messages`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            message: input,
            docu_id: docuId,
            document_session_id: localStorage.getItem('session_id'),
            page_number: localStorage.getItem('currentPageNumber')
          }),
        });

        const data = await response.json();
        const systemMessage: Message = { text: data.reply, sender: 'system' };
        setMessages((prevMessages) => [...prevMessages, systemMessage]);
      } catch (error) {
        const errorMessage: Message = { text: "Oups, couldn't get the answer. Please try again or reload the page", sender: 'system' };
        setMessages((prevMessages) => [...prevMessages, errorMessage]);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div>
      {/* <Fab color="primary" onClick={() => setOpen(false)} style={{ position: 'fixed', bottom: 16, right: 16 }}>
        <ChatIcon />
      </Fab> */}
      {open && (
        <Paper
          elevation={3}
          style={{
            position: 'fixed',
            bottom: 80,
            right: 16,
            width: '300px',
            maxHeight: '400px',
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '8px' }}>
            <span>Chat Bot</span>
            <IconButton size="small" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </div>
          <div style={{ overflowY: 'auto', flexGrow: 1, padding: '8px' }}>
            <List>
              {messages.map((message, index) => (
                <ListItem key={index} style={{ display: 'flex', justifyContent: message.sender === 'user' ? 'flex-end' : 'flex-start' }}>
                  <Box
                    sx={{
                      backgroundColor: message.sender === 'system' ? '#e0e0e0' : '#3f51b5',
                      color: message.sender === 'system' ? '#000' : '#fff',
                      borderRadius: '10px',
                      padding: '8px',
                      maxWidth: '80%',
                      whiteSpace: 'pre-wrap',
                    }}
                  >
                    <Typography variant="body2" style={{ whiteSpace: 'pre-wrap' }}>
                      {message.text}
                    </Typography>
                  </Box>
                </ListItem>
              ))}
            </List>
            {loading && <CircularProgress size={24} style={{ alignSelf: 'center', margin: '8px' }} />}
          </div>
          <div style={{ padding: '8px' }}>
            <TextField
              autoFocus
              margin="dense"
              label="Type your message"
              type="text"
              fullWidth
              variant="outlined"
              value={input}
              onChange={(e) => setInput(e.target.value)}
              multiline
              rows={3}
            />
            <Button onClick={handleSendMessage} color="primary" fullWidth style={{ marginTop: '8px' }}>
              Send
            </Button>
          </div>
        </Paper>
      )}
    </div>
  );
};

export default ChatBot;
