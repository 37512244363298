import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import PDFViewer from "components/SingleComponents/Helpers/PDFViewer";
import { DocumentProps } from './Search';
import { requestWrapper } from 'lib/api/auth';
import { Loading } from 'components/SingleComponents/Helpers/Loading';
import { Box, Button, Typography } from '@mui/material';
import PopUp from 'components/SingleComponents/Helpers/PopUp';
import { BASE_URL } from 'lib/api/client';
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import UploadPDF from "components/SingleComponents/UploadPDF";
import { useTranslation } from 'react-i18next';

interface PdfPageProps {}

const PdfPage: React.FC<PdfPageProps> = () => {
  const [currentDocument, setCurrentDocument] = useState<DocumentProps | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [docuId, setDocuId] = useState<string | null>("");
  const [searchParams] = useSearchParams();
  const [shareModalOpen, setShareModalOpen] = useState(false); 
  const [updateModalOpen, setUpdateModalOpen] = useState(false); 
  const navigate = useNavigate();
  const { t } = useTranslation("global");

  useEffect(() => {
    const fetchDocument = async () => {
      setIsLoading(true);
      setIsError(false);
      const docuId = searchParams.get('docuId');
      setDocuId(docuId);
      if (!docuId) {
        console.error('Document ID not specified');
        setIsError(true);
        setIsLoading(false);
        return;
      }
  
      try {
        const response = await requestWrapper(`${BASE_URL}/api/v1/documents/${docuId}`);
        setCurrentDocument(response);
        setIsLoading(false);
      } catch (e) {
        setIsError(true);
        setIsLoading(false);

        if (e === "Authentication tokens are missing.") {
          // eslint-disable-next-line no-self-assign
          window.location.href = window.location.href;
        } else {
        }
      }
    };

    fetchDocument();
  }, [shareModalOpen, updateModalOpen]);

  const handleShare = () => {
    setShareModalOpen(true); 
  };

  const handleShareModalClose = () => {
    setShareModalOpen(false);
  };

  const handleUpdate = () => {
    setUpdateModalOpen(true); 
  };

  const handleUpdateModalClose = () => {
    setUpdateModalOpen(false);
  };

  const handleAnalytics = () => {
    navigate(`/analytics?docuId=${docuId}`);
  };

  const renderLoading = () => <Loading />;
  
  const renderError = () => (
    <div>{t("mainSection.error-loading-document")}</div>
  );

  const renderDocument = () => (
    <>
      <PDFViewer 
        fileUrl={currentDocument!.file_url} 
      />
    </>
  );

  const renderNoDocument = () => (
    <div>{t("mainSection.no-document-found")}</div>
  );

  return (
    <div style={{ margin: "20px",}}>
      <Typography variant="h4" marginBottom={"10px"}>{currentDocument?.title || 'Document'}</Typography>
      <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom="30px">
        <Box>
          <Button variant="contained" color="primary" onClick={handleShare} style={{ marginRight: 10 }}>
          {t("mainSection.share")}
          </Button>
          <Button variant="contained" color="secondary" onClick={handleAnalytics} style={{ marginRight: 10 }}>
            {t("mainSection.analytics")}
          </Button>
          <Button variant="contained" color="warning" onClick={handleUpdate}>
            {t("mainSection.update-doc")}
          </Button>
        </Box>
      </Box>
      {isLoading ? renderLoading() : isError ? renderError() : currentDocument ? renderDocument() : renderNoDocument()}
      <PopUp isOpen={shareModalOpen} onClose={handleShareModalClose} />
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={updateModalOpen}
        onClose={handleUpdateModalClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={updateModalOpen}>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', outline: 'none' }}>
            <div style={{ backgroundColor: 'white', padding: '20px', border: '2px solid #000', boxShadow: '5px 5px 15px rgba(0,0,0,0.5)' }}>
              <h2 id="transition-modal-title">{t("mainSection.update-doc")}</h2>
              <p id="transition-modal-description">{t("mainSection.update-doc-desc")}</p>
              <UploadPDF onClose={handleUpdateModalClose} documentId={docuId as string} isUpdate={true} />
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default PdfPage;
