import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { makeStyles } from '@mui/styles';
import { useSearchParams } from 'react-router-dom';
import { requestWrapper } from 'lib/api/auth';
import { BASE_URL } from 'lib/api/client';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
  input: {
    marginBottom: '16px',
  },
  button: {
    marginTop: '16px',
  },
});

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  maxHeight: '80vh',
  overflowY: 'auto',
  paddingBottom: '8px',
  bgcolor: 'background.paper',
  border: '2px solid #3f51b5',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

interface PopUpProps {
  isOpen: boolean;
  onClose: () => void;
}

const PopUp: React.FC<PopUpProps> = ({ isOpen, onClose }) => {
  const [code, setCode] = React.useState('');
  const [searchParams] = useSearchParams();
  const classes = useStyles();
  const [urlModalOpen, setUrlModalOpen] = React.useState(false);
  const [newUrl, setNewUrl] = React.useState('');
  const [isPasscodeOptional, setIsPasscodeOptional] = React.useState(true);
  const [swipeDirection, setSwipeDirection] = React.useState('left_to_right');

  const { t } = useTranslation("global");
  
  const handleCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCode(event.target.value);
  };

  const handleSwipeDirectionChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSwipeDirection(event.target.value);
  };

  const handleSubmit = async () => {
    try {
      const docuId = searchParams.get('docuId');
      await requestWrapper(
        `${BASE_URL}/api/v1/documents/${docuId}/passcodes`,
        {
          method: 'POST',
          body: JSON.stringify({
            passcode: code,
            is_completely_open_access: true,
            swipe_direction: swipeDirection,
          }),
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );
      const currentUrl = window.location.href;
      let modifiedUrl = currentUrl.replace('view', 'shared');
      if (swipeDirection !== "left_to_right") {
        const url = new URL(modifiedUrl);
        const params = new URLSearchParams(url.search);
        params.set('dir', '1');
        url.search = params.toString();
        modifiedUrl = url.toString();
      }
      setNewUrl(modifiedUrl);
      setUrlModalOpen(true);
    } catch (error) {
      console.error('Failed to submit code', error);
    }
  };

  const handleUrlModalClose = () => {
    setUrlModalOpen(false);
    onClose();
    window.location.reload();
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(newUrl);
  };

  useEffect(() => {
    if (isOpen) {
      handleSubmit();
    }
  }, [isOpen, swipeDirection]);

  return (
    <>
      <Modal
        open={urlModalOpen}
        onClose={handleUrlModalClose}
        aria-labelledby="url-modal-title"
        aria-describedby="url-modal-description"
      >
        <Box sx={style}>
          <h3 id="swip-direction">{t("mainSection.swip-direction")}</h3>
          <RadioGroup
            value={swipeDirection}
            onChange={handleSwipeDirectionChange}
            row
          >
            <FormControlLabel
              value="left_to_right"
              control={<Radio />}
              label={t("mainSection.left-right")}
            />
            <FormControlLabel
              value="right_to_left"
              control={<Radio />}
              label={t("mainSection.right-left")}
            />
          </RadioGroup>

          <h3 id="url-modal-title">{t("mainSection.share-link")}</h3>
          <TextField
            id="url-output"
            label={t("mainSection.link")}
            variant="outlined"
            fullWidth
            value={newUrl}
            margin="normal"
            InputProps={{
              readOnly: true,
            }}
          />
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={handleCopy}
            style={{ marginRight: '8px' }}
          >
            {t("mainSection.copy")}
          </Button>
          <Button
            variant="contained"
            color="secondary"
            className={classes.button}
            onClick={handleUrlModalClose}
          >
            {t("mainSection.close")}
          </Button>
        </Box>
      </Modal>
    </>
  );
};

export default PopUp;
