import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom';
import Cookies from "js-cookie";

import { makeStyles, Theme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import CssBaseline from "@material-ui/core/CssBaseline";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";

import { AuthContext } from "App";
import UploadPDF from "components/SingleComponents/UploadPDF";

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    minHeight: '100vh',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: theme.palette.primary.main,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2),
    ...theme.mixins.toolbar,
    backgroundColor: theme.palette.primary.main,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    flexGrow: 1,
    textDecoration: "none",
    color: theme.palette.common.white,
  },
  linkBtn: {
    textTransform: "none",
    color: theme.palette.common.white,
  },
  iconButton: {
    marginRight: theme.spacing(2),
    color: theme.palette.common.white,
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  selectedItem: {
    backgroundColor: theme.palette.common.white,
    color: '#3f51b5',
    fontWeight: 'bold',
  },
}));

const VerticalHeader: React.FC = () => {
  const { loading, isSignedIn, setIsSignedIn } = useContext(AuthContext);
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<string | null>('dashboard');
  
  const location = useLocation();

  const { t } = useTranslation("global");

  useEffect(() => {
    const path = location.pathname;
    if (path === "/") {
      setSelectedItem("dashboard");
    } else if (path.startsWith("/flow")) {
      setSelectedItem("flow");
    } else if (path.startsWith("/files")) {
      setSelectedItem("documents");
    } else if (path.startsWith("/settings/salesforce")) {
      setSelectedItem("salesforce-settings");
    } else if (path.startsWith("/settings/chatworks")) {
      setSelectedItem("chatworks-settings");
    } else if (path.startsWith("/signin")) {
      setSelectedItem("signin");
    } else if (path.startsWith("/signup")) {
      setSelectedItem("signup");
    }

    console.log("selectedItem", selectedItem);
  }, [location.pathname]);

  const handleSignOut = async () => {
    try {
      Cookies.remove("jwt");
      setIsSignedIn(false);
    } catch (err) {
      console.error("Sign out error:", err);
    }
  };

  const handleUpload = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSettingsClick = () => {
    setSettingsOpen(!settingsOpen);
  };

  const handleItemClick = (item: string) => {
    setSelectedItem(item);
  };

  const AuthButtons = () => {
    if (!loading) {
      if (isSignedIn) {
        return (
          <>
            <ListItem 
              button 
              component={Link} 
              to="/" 
              onClick={() => handleItemClick('dashboard')}
              className={selectedItem === 'dashboard' ? classes.selectedItem : ''}
            >
              {/* <ListItemText primary="ダッシュボード" /> */}
              <ListItemText primary={t("mainSection.dashbord-title")} />
            </ListItem>
            <ListItem 
              button 
              component={Link} 
              to="/flow" 
              onClick={() => handleItemClick('flow')}
              className={selectedItem === 'flow' ? classes.selectedItem : ''}
            >
              <ListItemText primary={t("mainSection.flow-analytics")} />
            </ListItem>
            <ListItem 
              button 
              component={Link} 
              to="/files" 
              onClick={() => handleItemClick('documents')}
              className={selectedItem === 'documents' ? classes.selectedItem : ''}
            >
              <ListItemText primary={t("mainSection.documents")} />
            </ListItem>
            <ListItem 
              button 
              onClick={() => {
                handleUpload();
              }}
            >
              <ListItemText primary={t("mainSection.add-doc")} />
            </ListItem>
            <ListItem 
              button 
              onClick={() => {
                handleSettingsClick();
              }}
            >
              <ListItemText primary={t("mainSection.settings")} />
              {settingsOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={settingsOpen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem 
                  button 
                  component={Link} 
                  to="/settings/salesforce"
                  onClick={() => handleItemClick('salesforce-settings')}
                  className={selectedItem === 'salesforce-settings' ? classes.selectedItem : ''}
                >
                  <ListItemText primary={t("mainSection.salesforce-settings-tab")} />
                </ListItem>
              </List>
              <List component="div" disablePadding>
                <ListItem 
                  button 
                  component={Link} 
                  to="/settings/chatworks"
                  onClick={() => handleItemClick('chatworks-settings')}
                  className={selectedItem === 'chatworks-settings' ? classes.selectedItem : ''}
                >
                  <ListItemText primary={t("mainSection.chatwork-setting-tab")}/>
                </ListItem>
              </List>
            </Collapse>
            <ListItem 
              button 
              onClick={handleSignOut}
              className={selectedItem === 'signout' ? classes.selectedItem : ''}
            >
              <ListItemText primary={t("mainSection.logout")} />
            </ListItem>
          </>
        );
      } else {
        return (
          <>
            <ListItem 
              component={Link} 
              to="/signup" 
              button 
              onClick={() => handleItemClick('signup')}
              className={selectedItem === 'signup' ? classes.selectedItem : ''}
            >
              <ListItemText primary={t("mainSection.create-account")} />
            </ListItem>
            <ListItem 
              component={Link} 
              to="/signin" 
              button 
              onClick={() => handleItemClick('signin')}
              className={selectedItem === 'signin' ? classes.selectedItem : ''}
            >
              <ListItemText primary={t("mainSection.login")} />
            </ListItem>
          </>
        );
      }
    } else {
      return null;
    }
  };
  
  const list = () => (
    <div
      className={classes.linkBtn}
      role="presentation"
    >
      <div className={classes.drawerHeader}>
        <Typography
          component={Link}
          to="/"
          variant="h6"
          className={classes.title}
        >
          FilesLabs
        </Typography>
      </div>
      <List>
        <AuthButtons />
      </List>
    </div>
  );

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <Typography
            component={Link}
            to="/"
            variant="h6"
            className={classes.title}
          >
            FilesLabs
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor="left"
      >
        {list()}
      </Drawer>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <h2 id="transition-modal-title">{t("mainSection.add-doc")}</h2>
            <p id="transition-modal-description">{t("mainSection.add-doc-desc")}</p>
            <UploadPDF onClose={handleClose}/>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default VerticalHeader;
