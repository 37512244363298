import React, { useState, useEffect } from 'react';
import { Paper, Typography } from '@mui/material';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { requestWrapper } from 'lib/api/auth';
import { useSearchParams } from 'react-router-dom';
import { BASE_URL } from 'lib/api/client';
import { useTranslation } from 'react-i18next';

const fetchDataFromApi = async (passcodeId: string | undefined, docuId: string | null) => {
  try {
    const data = passcodeId !== undefined ? await requestWrapper(`${BASE_URL}/api/v1/page_sessions/${passcodeId}/daily_access?document_id=${docuId}`, {}, false) : [];
    return data["daily_access"]
  } catch (error) {
    console.error("Failed to fetch data from API:", error);
    return [];
  }
};

export interface ChartProps {
  passcodeId: string | undefined;
}

const Chart = ({ passcodeId }: ChartProps) => {
  const [data, setData] = useState([]);
  const [searchParams] = useSearchParams();
  const { t } = useTranslation("global");
  const docuId = searchParams.get('docuId');

  useEffect(() => {
    const loadData = async () => {
      const fetchedData = await fetchDataFromApi(passcodeId, docuId);
      setData(fetchedData);
    };
    
    loadData();
  }, [passcodeId, docuId]);

  return (
    <Paper style={{ margin: 20, maxWidth: '100%' }}>
      <Typography variant="h6" style={{ margin: 10 }}>
      {t("mainSection.access-chart-title")}
      </Typography>
      <ResponsiveContainer height={300}>
        <LineChart data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" tickFormatter={(date) => new Date(date).toLocaleDateString()} />
          <YAxis/>
          <Tooltip labelFormatter={(date) => new Date(date).toLocaleDateString()} />
          <Legend />
          <Line type="monotone" dataKey="accessCount" stroke="#2566e8" />
        </LineChart>
      </ResponsiveContainer>
    </Paper>
  );
};

export default Chart;
