import React, { useContext, useState } from "react";
import { Link } from 'react-router-dom';
import Cookies from "js-cookie";

import { makeStyles, Theme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";

import { AuthContext } from "App";
import UploadPDF from "components/SingleComponents/UploadPDF";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) => ({
  iconButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    textDecoration: "none",
    color: "inherit",
  },
  linkBtn: {
    textTransform: "none",
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const HorizontalHeader: React.FC = () => {
  const { loading, isSignedIn, setIsSignedIn } = useContext(AuthContext);
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const { t } = useTranslation("global");

  const handleSignOut = async (e: React.MouseEvent<HTMLButtonElement>) => {
    try {
      if (true) {
        Cookies.remove("jwt");

        setIsSignedIn(false);
      } else {
      }
    } catch (err) {
    }
  };

  const handleUpload = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const AuthButtons = () => {
    if (!loading) {
      if (isSignedIn) {
        return (
          <>
            <Button
              color="inherit"
              className={classes.linkBtn}
              onClick={handleUpload}
            >
              {t("mainSection.add-doc")}
            </Button>
            <Button
              color="inherit"
              className={classes.linkBtn}
              onClick={handleSignOut}
            >
              {t("mainSection.logout")}
            </Button>
          </>
        );
      } else {
        return (<></>);
      }
    } else {
      return <></>;
    }
  };

  return (
    <>
      <header>
        <AppBar position="static">
          <Toolbar>
            <Typography
              component={Link}
              to="/"
              variant="h6"
              className={classes.title}
            >
              FilesLabs
            </Typography>
            <AuthButtons />
          </Toolbar>
        </AppBar>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open}>
            <div className={classes.paper}>
              <h2 id="transition-modal-title">{t("mainSection.add-doc")}</h2>
              <p id="transition-modal-description">{t("mainSection.add-doc-desc")}</p>
              <UploadPDF onClose={handleClose}/>
            </div>
          </Fade>
        </Modal>
      </header>
  </>
  );
};

export default HorizontalHeader;