import React, { useEffect, useRef, useState, useCallback } from 'react';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import packageJson from '../../../../../package.json';
import { BASE_URL } from 'lib/api/client';
import { requestWrapper } from 'lib/api/auth';
import { useTranslation } from 'react-i18next';

interface HighlightProps {
  fileUrl: string;
  onDocumentLoad?: () => void;
  onPageChange?: (page: number) => void;
  passcodeId?: string;
}

const characterMap = {
  isCompressed: true,
  url: "https://unpkg.com/pdfjs-dist@2.16.105/cmaps/",
};

const fetchMyHighlights = async (passcodeId: string | undefined): Promise<any[]> => {
  try {
    const response = passcodeId !== undefined ?
      await requestWrapper(`${BASE_URL}/api/v1/mouse_analytics/hover_data?passcode_id=${passcodeId}`) : 
      [];
    return response;
  } catch (error) {
    console.error("Failed to fetch messages from API:", error);
    return [];
  }
};

const Highlight: React.FC<HighlightProps> = ({
  fileUrl,
  onDocumentLoad,
  onPageChange,
  passcodeId,
}) => {
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const pdfjsVersion = packageJson.dependencies['pdfjs-dist'];
  const viewerRef = useRef<HTMLDivElement | null>(null);
  const [isDocumentLoaded, setIsDocumentLoaded] = useState(false);
  const [textsToHighlight, setTextsToHighlight] = useState<{ hovered_content: string; page: number }[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const { t } = useTranslation("global");

  const handleDocumentLoaded = (e: any) => {
    setIsDocumentLoaded(true);
    setTotalPages(e.doc.numPages);
    if (onDocumentLoad) onDocumentLoad();
  };

  const handlePageChanged = (e: any) => {
    setCurrentPage(e.currentPage + 1);
    if (onPageChange) onPageChange(e.currentPage);
  };

  useEffect(() => {
    const fetchHighlights = async () => {
      const highlights = await fetchMyHighlights(passcodeId);
      setTextsToHighlight(highlights);
    };

    fetchHighlights();
  }, [passcodeId, isDocumentLoaded]);

  const highlightText = useCallback(() => {
    textsToHighlight.forEach(({ hovered_content, page }) => {
      const pageContainer = viewerRef.current?.querySelector(`[aria-label="Page ${page + 1}"]`);
      const pageTextLayer = pageContainer?.querySelector('.rpv-core__text-layer');
      
      if (pageTextLayer) {
        const textElements = pageTextLayer.querySelectorAll('.rpv-core__text-layer-text');
        const textCount = Array.from(textElements).filter(element => element.textContent === hovered_content).length;
        const maxCount = textCount || 1; // Avoid division by zero
        
        textElements.forEach(element => {
          if (element.textContent === hovered_content) {
            const opacity = textCount / maxCount;
            (element as HTMLElement).style.backgroundColor = `rgba(0, 255, 0, ${opacity})`;
            element.classList.add('highlighted');
          }
        });
      }
    });
  }, [textsToHighlight]);

  useEffect(() => {
    if (isDocumentLoaded && viewerRef.current) {
      const observer = new MutationObserver((mutations) => {
        for (const mutation of mutations) {
          if (mutation.type === 'childList' && mutation.addedNodes.length > 0) {
            highlightText();
          }
        }
      });

      const config = { childList: true, subtree: true };

      observer.observe(viewerRef.current, config);

      return () => {
        observer.disconnect();
      };
    }
  }, [highlightText, isDocumentLoaded, textsToHighlight]);

  return (
    <Worker workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjsVersion}/build/pdf.worker.min.js`}>
      <div style={{ height: '1024px', position: 'relative' }} ref={viewerRef}>
        <style>{`
          .highlighted {
            background-color: rgba(0, 255, 0, 0.5);
          }
          .page-number {
            position: absolute;
            bottom: 10px;
            right: 10px;
            background: rgba(0, 0, 0, 0.8); /* Black background */
            color: white;
            padding: 5px 10px;
            border-radius: 3px;
            font-size: 14px;
            box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
          }
        `}</style>
        <Viewer
          characterMap={characterMap}
          fileUrl={fileUrl}
          plugins={[defaultLayoutPluginInstance]}
          onDocumentLoad={handleDocumentLoaded}
          onPageChange={handlePageChanged}
        />
        <div className="page-number">${t("mainSection.page")} {currentPage}/{totalPages}</div>
      </div>
    </Worker>
  );
};

export default Highlight;
