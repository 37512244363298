import { Stack } from "@mui/material";
import { useEffect, useState } from "react";
import DocumentOptions from "./DocumentOptions";
import SankeyDiagram from "../flow/SankeyDiagram";

export interface documentId {
  children: React.ReactNode; 
}

const Flow = () => {
  const [selectedDocumentId, setSelectedDocumentId] = useState<string>();

  const handleOptionChange = (optionId: string) => {
    setSelectedDocumentId(optionId);
  };

  useEffect(() => {
  }, [selectedDocumentId]);

  return (
    <div>
      <Stack direction="column" spacing="20px" padding="10px" marginTop="16px" maxWidth= '100%'>
        <DocumentOptions onOptionChange={handleOptionChange}/>
        {selectedDocumentId && <SankeyDiagram documentId={selectedDocumentId} />}
      </Stack>
    </div>
  )
}

export default Flow;


