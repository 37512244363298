import { useState, useEffect } from 'react';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { requestWrapper } from 'lib/api/auth';
import { BASE_URL } from 'lib/api/client';
import { useTranslation } from 'react-i18next';

interface RowData {
  date: string;
  accessCount: number;
}

const fetchDataFromApi = async (passcodeId: string | undefined): Promise<RowData[]> => {
  try {
    const response = passcodeId !== undefined ? await requestWrapper(
      `${BASE_URL}/api/v1/page_visited/page_rank_by_visits_for_passcode?passcode_id=${passcodeId}`) : [];
    response.sort((a: RowData, b: RowData) => b.accessCount - a.accessCount);

    return response;
  } catch (error) {
    console.error("Failed to fetch data from API:", error);
    return [];
  }
};

export interface PageRankingProps {
  passcodeId: string | undefined; 
}

const PageRanking = ({ passcodeId }: PageRankingProps) => {
  const { t } = useTranslation("global");
  const [rows, setRows] = useState<RowData[]>([]); 

  useEffect(() => {
    fetchDataFromApi(passcodeId).then(setRows);
  }, [passcodeId]);

  return (
    <Paper style={{ margin: 20, width: '100%' }}>
      <Typography variant="h4" style={{ margin: 10 }}>
      {t("mainSection.page-rank-title")}
      </Typography>
      <TableContainer style={{ maxHeight: 400, overflow: 'auto' }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography variant="subtitle2" style={{ fontWeight: 'bold' }}>{t("mainSection.page")}</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle2" style={{ fontWeight: 'bold' }}>{t("mainSection.visits-count")}</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow key={index}>
                <TableCell>{row.date}</TableCell>
                <TableCell>{row.accessCount}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default PageRanking;
