import React, { useEffect, useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import type { SxProps } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { ArrowDown as ArrowDownIcon } from '@phosphor-icons/react/dist/ssr/ArrowDown';
import { ArrowUp as ArrowUpIcon } from '@phosphor-icons/react/dist/ssr/ArrowUp';
import { ShareFat as ShareFat } from '@phosphor-icons/react/dist/ssr/ShareFat';
import { requestWrapper } from 'lib/api/auth';
import { useSearchParams } from 'react-router-dom';
import { BASE_URL } from 'lib/api/client';

export interface NumberCardProps {
  diff?: number;
  trend: 'up' | 'down';
  sx?: SxProps;
  passcodeId: string | undefined; // Changed prop name to indicate API endpoint
  title: string;
  documentId?: string;
}

export function NumberCard({ 
  diff,
  trend,
  sx, 
  passcodeId,
  title,
  documentId
}: NumberCardProps): React.JSX.Element {
  const [value, setValue] = useState<string>('');
  const [searchParams] = useSearchParams();
  const docuId = searchParams.get('docuId') || documentId;

  useEffect(() => {
    const fetchValue = async () => {
      try {
        const response = passcodeId !== undefined ? await requestWrapper(`${BASE_URL}/api/v1/page_sessions/${passcodeId}/shares?document_id=${docuId}`) : "";
        setValue(response["number_of_doc_shares"]);
      } catch (error) {
        console.error('Error fetching value:', error);
      }
    };

    fetchValue();
  }, [docuId, passcodeId]);

  const TrendIcon = trend === 'up' ? ArrowUpIcon : ArrowDownIcon;
  const trendColor = trend === 'up' ? 'var(--mui-palette-success-main)' : 'var(--mui-palette-error-main)';

  return (
    <Card sx={sx}>
      <CardContent>
        <Stack spacing={3}>
          <Stack 
            direction="row" 
            sx={{ 
              alignItems: 'flex-start', 
              justifyContent: 'space-between' 
            }}
            spacing={3}
          >
            <Stack spacing={1}>
              <Typography color="black" variant="overline">
                {title}
              </Typography>
              <Typography variant="h4" color="#2566e8">{value}</Typography>
            </Stack>
            <Avatar sx={{ backgroundColor: 'var(--mui-palette-primary-main)', height: '56px', width: '56px' }}>
              <ShareFat fontSize="var(--icon-fontSize-lg)" />
            </Avatar>
          </Stack>
          {diff ? (
            <Stack sx={{ alignItems: 'center' }} direction="row" spacing={2}>
              <Stack sx={{ alignItems: 'center' }} direction="row" spacing={0.5}>
                <TrendIcon color={trendColor} fontSize="var(--icon-fontSize-md)" />
                <Typography color={trendColor} variant="body2">
                  {diff}%
                </Typography>
              </Stack>
              <Typography color="text.secondary" variant="caption">
                Since last month
              </Typography>
            </Stack>
          ) : null}
        </Stack>
      </CardContent>
    </Card>
  );
}
