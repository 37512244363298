import React, { useState, useEffect } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import {
  Container,
  TextField,
  Button,
  Typography,
  Grid,
  Paper,
  IconButton,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { requestWrapper } from 'lib/api/auth';
import { BASE_URL } from 'lib/api/client';
import { useTranslation } from 'react-i18next';

interface IFormInput {
  client_id: string;
  client_secret: string;
  instance_url: string;
}

const SalesforceSettings: React.FC = () => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [defaultValues, setDefaultValues] = useState<IFormInput | null>(null);
  const { register, handleSubmit, formState: { errors }, reset, setValue } = useForm<IFormInput>();
  const { t } = useTranslation("global");

  // Fetch existing settings when the component mounts
  useEffect(() => {
    const fetchSettings = async () => 
    {
      try {
        const response = await requestWrapper(`${BASE_URL}/api/v1/salesforce_credential`, {
          method: 'GET',
        });
        reset(response);
        setDefaultValues(response);
        setValue('client_id', response.client_id);
        setValue('client_secret', response.client_secret);
        setValue('instance_url', response.instance_url);
      } catch (error) {
        console.error('Error fetching Salesforce settings:', error);
      }
    }
    fetchSettings();
  }, [reset, setValue]);

  const onSubmit: SubmitHandler<IFormInput> = async (data) => {
    try {
      await requestWrapper(`${BASE_URL}/api/v1/salesforce_credential`, {
        method: 'POST',
        body: {
          salesforce_credential: {
            client_id: data.client_id,
            client_secret: data.client_secret,
            instance_url: data.instance_url,
          },
        },
      });

      alert('Salesforce credentials saved successfully.');
      setIsEditMode(false);
    } catch (error) {
      console.error('Error saving Salesforce credentials:', error);
      alert('An error occurred. Please try again.');
    }
  };

  const handleEditClick = () => {
    setIsEditMode(true);
  };

  return (
    <Container maxWidth="sm">
      <Paper elevation={3} sx={{ p: 4, mt: 4 }}>
        <Typography variant="h5" gutterBottom>
          {t("mainSection.salesforce-settings-title")}
          {!isEditMode && (
            <IconButton onClick={handleEditClick} aria-label="edit">
              <EditIcon />
            </IconButton>
          )}
        </Typography>
        <Typography variant="body2" gutterBottom>
          {t("mainSection.salesforce-settings-desc")}
        </Typography>

        {isEditMode ? (
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  label={t("mainSection.client-id")}
                  fullWidth
                  {...register('client_id', { required: t("mainSection.require-client-id") })}
                  error={!!errors.client_id}
                  helperText={errors.client_id?.message}
                  defaultValue={defaultValues?.client_id}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  label="クライアントシクレート"
                  fullWidth
                  {...register('client_secret', { required: t("mainSection.require-client-secret") })}
                  error={!!errors.client_secret}
                  helperText={errors.client_secret?.message}
                  defaultValue={defaultValues?.client_secret}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  label={t("mainSection.instance-url")}
                  fullWidth
                  {...register('instance_url', { required: t("mainSection.require-instance-url") })}
                  error={!!errors.instance_url}
                  helperText={errors.instance_url?.message}
                  defaultValue={defaultValues?.instance_url}
                />
              </Grid>

              <Grid item xs={12}>
                <Button variant="contained" color="primary" type="submit" fullWidth>
                  保存
                </Button>
              </Grid>
            </Grid>
          </form>
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="subtitle1">クライアントID: {defaultValues?.client_id}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1">クライアントシクレート: {defaultValues?.client_secret}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1">インスタンスURL: {defaultValues?.instance_url}</Typography>
            </Grid>
          </Grid>
        )}
      </Paper>
    </Container>
  );
};

export default SalesforceSettings;
