import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { LinearProgress } from '@material-ui/core';
import PDFViewer from "components/SingleComponents/Helpers/PDFViewer";
import { DocumentProps } from './Search';
import { requestWrapper } from 'lib/api/auth';
import { Loading } from 'components/SingleComponents/Helpers/Loading';
import { Button, TextField, Typography, IconButton } from '@material-ui/core';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { createAnalyticsManager } from 'components/utils/analyticsManager';
import ChatBot from 'components/SingleComponents/ChatBot';
import { BASE_URL, cloudFrontDomain, s3Domain } from 'lib/api/client';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';

const ProtectPDFPage: React.FC = () => {
  const [searchParams] = useSearchParams();
  const docuId = searchParams.get('docuId');
  const direction = searchParams.get('dir');
  const [currentDocument, setCurrentDocument] = useState<DocumentProps | null>(null);
  const [initializeChat, setInitializeChat] = useState<boolean>(false);
  const [passcode, setPasscode] = useState<string>(Cookies.get(`passcode_or_email_${docuId}`) || '');
  const [checkPasscode, setCheckPasscode] = useState<Boolean>(Cookies.get(`passcode_or_email_${docuId}`) !== undefined);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState<string>('');
  const [currentPageIndex, setCurrentPageIndex] = useState<number>(0);
  const [pageSessionId, setPageSessionId] = useState<string | null>(null);
  const analyticsManager = createAnalyticsManager(setPageSessionId, docuId);

  const navigate = useNavigate();
  const { t } = useTranslation("global");

  useEffect(() => {
    const fetchDocument = async () => {
      if (checkPasscode === false) {
        return;
      }

      setIsLoading(true);
      setIsError(false);
      const docuId = searchParams.get('docuId');
      if (!docuId) {
        console.error('Document ID not specified');
        setIsError(true);
        setIsLoading(false);
        return;
      }

      try {
        const response = await requestWrapper(
          `${BASE_URL}/api/v1/share/${docuId}`,
          {
            headers: { passcode: passcode }
          },
          false
        );
        setCurrentDocument(response);
        setIsLoading(false);
        Cookies.set(`passcode_or_email_${docuId}`, passcode);
        await analyticsManager.startSession(passcode);

        const savedPageIndex = localStorage.getItem(`lastPage_${docuId}`);
        if (savedPageIndex !== null) {
          setCurrentPageIndex(parseInt(savedPageIndex, 10));
        } else {
          handlePageChange(0);
        }
      } catch (e) {
        setIsError(true);
        setIsLoading(false);
        setError('資料が見つかりません。削除されたか、非公開に設定されています。');
      }
    };

    fetchDocument();

    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      return '';
    };

    window.addEventListener('beforeunload', handleBeforeUnload, {capture: true});

    return () => {
      analyticsManager.endSession();
      window.removeEventListener('beforeunload', handleBeforeUnload, {capture: true});
    };
  }, [checkPasscode, passcode, error, navigate]);

  const handlePageChange = (pageIndex: number) => {
    setCurrentPageIndex(pageIndex);
    analyticsManager.trackEvent('page_change', { page: pageIndex, session_id: pageSessionId });
    localStorage.setItem('currentPageNumber', (pageIndex).toString());
    localStorage.setItem(`lastPage_${docuId}`, pageIndex.toString());
    setInitializeChat(true);
  };

  const handleMouseHover = (contentText: string) => {
    analyticsManager.trackEvent(
      'mouse_hover', 
      { 
        page: currentPageIndex, 
        contentText: contentText
      }
    );
  }

  const handlePasscodeChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setPasscode(event.target.value);
  };

  const handlePasscodeSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    setCheckPasscode(true);
  };

  const renderLoading = () => <Loading />;

  const renderError = () => (
    <div>{t("mainSection.error-loading-document")}</div>
  );

  const renderDocument = () => {

    if (!currentDocument) return null;

    const pageCount = currentDocument.page_urls.length;
    const progress = (currentPageIndex + 1) / pageCount * 100;


    const handlePreviousPage = () => {
      if (currentPageIndex > 0) {
        handlePageChange(currentPageIndex - 1);
      }
    };

    const handleNextPage = () => {
      if (currentPageIndex < pageCount - 1) {
        handlePageChange(currentPageIndex + 1);
      }
    };

    return (
      <div style={{ position: 'relative' }}>
        <Typography variant="body1" style={{ textAlign: 'center', marginBottom: '10px' }}>
          {`${t("mainSection.doc-search-label")} ${currentPageIndex + 1} / ${pageCount}`}
        </Typography>

        <LinearProgress 
          variant="determinate" 
          value={progress} 
          style={{ marginBottom: '20px', height: '10px' }}
        />
        <IconButton
          onClick={direction !== "1" ? handlePreviousPage : handleNextPage}
          disabled={direction !== "1" ? currentPageIndex === 0 : currentPageIndex === pageCount - 1 }
          style={{ 
            left: 0,
            top: '50%',
            zIndex: 10,
            position: 'absolute',
            transform: 'translateY(-50%)', 
          }}
        >
          <ChevronLeftIcon fontSize="large" style={{ fontSize: 60, color: '#1976d2' }} />
        </IconButton>
        <PDFViewer 
          fileUrl={currentDocument.page_urls[currentPageIndex].replace(s3Domain, cloudFrontDomain).split('?')[0]} 
          onHover={handleMouseHover}
        />
        <IconButton
          onClick={direction !== "1" ? handleNextPage : handlePreviousPage}
          disabled={direction !== "1" ? currentPageIndex === pageCount - 1 : currentPageIndex === 0}
          style={{ 
            right: 0, 
            top: '50%',
            zIndex: 10,
            position: 'absolute', 
            transform: 'translateY(-50%)', 
          }}
        >
          <ChevronRightIcon fontSize="large" style={{ fontSize: 60, color: '#1976d2' }} />
        </IconButton>
      </div>
    );
  };


  const renderPasscode = () => (
    <>
      <form onSubmit={handlePasscodeSubmit}>
        <TextField
          label={t("mainSection.email-label")}
          variant="outlined"
          type="string"
          fullWidth
          value={passcode}
          onChange={handlePasscodeChange}
          required
          style={{ marginBottom: 20 }}
        />
        <Button type="submit" variant="contained" color="primary" disabled={passcode === ""}>
          アクセスする
        </Button>
        {error && <Typography color="error" style={{ marginTop: 20 }}>{error}</Typography>}
      </form>
    </>
  );

  const renderNoDocument = () => (
    <div>{t("mainSection.no-document-found")}</div>
  );

  const isMobile = window.innerWidth <= 768;
  const viewerHeightPosition = isMobile ? '200px' : '20px';

  return (
    <>
      <div style={{ marginTop: viewerHeightPosition }}>
        {!currentDocument && !Cookies.get(`passcode_or_email_${docuId}`)
          ? renderPasscode()
          : isLoading
          ? renderLoading()
          : isError
          ? renderError()
          : currentDocument
          ? renderDocument()
          : renderNoDocument()}
      </div>
      {currentDocument && initializeChat && <ChatBot />}
    </>
  );
};

export default ProtectPDFPage;
