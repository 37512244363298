import React, { useState, ChangeEvent } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import { requestWrapper } from 'lib/api/auth';
import { BASE_URL } from 'lib/api/client';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
    position: 'relative',
    display: 'inline-block',
  },
  input: {
    display: 'none',
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    zIndex: 1,
  },
}));

interface UploadPDFProps {
  onClose: () => void;
  isUpdate?: boolean;
  documentId?: string;
}

const UploadPDF: React.FC<UploadPDFProps> = ({ onClose, isUpdate, documentId }) => {
  const classes = useStyles();
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const { t } = useTranslation("global");
  
  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setSelectedFile(event.target.files[0]);
    }
  };

  const handleUpload = async () => {
    if (selectedFile) {
      const formData = new FormData();
      formData.append('document[file]', selectedFile);
      const url = isUpdate ? `${BASE_URL}/api/v1/documents/${documentId}` : `${BASE_URL}/api/v1/documents`;
      setLoading(true);
      try {
        await requestWrapper(url, {
          method: isUpdate ? 'PATCH' : 'POST',
          body: formData,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        onClose();
        window.location.reload();
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div className={classes.root}>
      <input
        accept="application/pdf"
        className={classes.input}
        id="contained-button-file"
        multiple={false}
        type="file"
        onChange={handleFileChange}
      />
      <label htmlFor="contained-button-file">
        <Button
          variant="contained"
          color="default"
          component="span"
          startIcon={<CloudUploadIcon />}
        >
          {t("mainSection.choose-pdf")}
        </Button>
      </label>
      <Button
        variant="contained"
        color="primary"
        onClick={handleUpload}
        disabled={!selectedFile || loading}
      >
        アプロード
      </Button>
      {selectedFile && (
        <Typography variant="body1" component="p">
          選択したファイル: {selectedFile.name}
        </Typography>
      )}
      {loading && (
        <div className={classes.overlay}>
          <CircularProgress />
        </div>
      )}
    </div>
  );
};

export default UploadPDF;
