import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

const useStyles = makeStyles((theme: Theme) => ({
  footer: {
    marginTop: theme.spacing(4),
    padding: theme.spacing(2, 0),
    backgroundColor: theme.palette.background.paper,
    textAlign: 'center',
  },
  link: {
    textDecoration: 'none',
    color: 'inherit',
  },
}));

const Footer: React.FC = () => {
  const classes = useStyles();

  return (
    <footer className={classes.footer}>
      <Container>
        <Typography variant="body2" color="textSecondary">
          <Link to="https://landing.fileslabs.com/privacy-policy" className={classes.link}>
            Privacy Policy
          </Link>
        </Typography>
      </Container>
    </footer>
  );
};

export default Footer;
