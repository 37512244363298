import { Stack } from "@mui/material";
import { useEffect, useState } from "react";
import AnalyticsPage from "../Analytics";
import DocumentOptions from "./DocumentOptions";

export interface documentId {
  children: React.ReactNode; 
}

const Dashbaord = () => {
  const [selectedDocumentId, setSelectedDocumentId] = useState<string>();

  const handleOptionChange = (optionId: string) => {
    setSelectedDocumentId(optionId);
  };

  useEffect(() => {

  }, [selectedDocumentId]);

  return (
    <div>
      <Stack direction="column" spacing="20px" padding="10px" marginTop="16px" maxWidth= '100%'>
        <DocumentOptions onOptionChange={handleOptionChange}/>
        <AnalyticsPage documentId={selectedDocumentId}/>
      </Stack>
    </div>
  )
}

export default Dashbaord;


