import React, { useEffect } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { LANDING_PAGE_URL } from "lib/api/client";
import { useTranslation } from "react-i18next";

const MobileWarningDialog: React.FC<{ open: boolean }> = ({ open }) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (open) {
      const timer = setTimeout(() => {
        window.location.href = LANDING_PAGE_URL;
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [open, navigate]);

  const handleClose = () => {
    window.location.href = LANDING_PAGE_URL;
  };

  const { t } = useTranslation("global");

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{t("mainSection.mobile-redirect-alert-title")}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
        {t("mainSection.mobile-redirect-alert-desc-1")}<br />
        {t("mainSection.mobile-redirect-alert-desc-2")}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleClose} color="primary">
        {t("mainSection.redirect")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MobileWarningDialog;
