import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { DocumentProps } from './Search';
import { requestWrapper } from 'lib/api/auth';
import { Loading } from 'components/SingleComponents/Helpers/Loading';
import Highlight from './anaylytics/contentAnalytics/Highlight';
import { BASE_URL, cloudFrontDomain, s3Domain } from 'lib/api/client';
import { Paper, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface InPageAnalyticsProps {
  passcodeId?: string;
  docId?: string;
}

const InPageAnalytics: React.FC<InPageAnalyticsProps> = ({ passcodeId, docId }) => {
  const [searchParams] = useSearchParams();
  const [currentDocument, setCurrentDocument] = useState<DocumentProps | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const { t } = useTranslation("global");

  useEffect(() => {
    const fetchDocument = async () => {
      setIsLoading(true);
      setIsError(false);
      
      const docuId = docId || searchParams.get('docuId'); // Use docId prop or fallback to URL param
      if (!docuId) {
        console.error('Document ID not specified');
        setIsError(true);
        setIsLoading(false);
        return;
      }
  
      try {
        const response = await requestWrapper(`${BASE_URL}/api/v1/documents/${docuId}`);
        setCurrentDocument(response);
        setIsLoading(false);
      } catch (e) {
        setIsError(true);
        setIsLoading(false);

        if (e === "Authentication tokens are missing.") {
          // eslint-disable-next-line no-self-assign
          window.location.href = window.location.href;
        } else {
          console.error('Error loading document', e);
        }
      }
    };

    fetchDocument();
  }, [docId, passcodeId, searchParams]);

  const renderLoading = () => <Loading />;
  
  const renderError = () => (
    <div>
      {t("mainSection.error-loading-document")}
    </div>
  );

  const renderDocument = () => (
    <Paper style={{maxWidth: '100%' }}>
      <Typography variant="h4" style={{ margin: 10 }}>{t("mainSection.heat-map-title")}</Typography>
        <Highlight
          fileUrl={currentDocument!.file_url.replace(s3Domain, cloudFrontDomain).split('?')[0]}
          passcodeId={passcodeId}
        />
    </Paper>
  );

  const renderNoDocument = () => (
    <div>
      {t("mainSection.no-document-found")}
    </div>
  );

  return (
    <div style={{ margin: "20px"}}>
      {isLoading ? renderLoading() : isError ? renderError() : currentDocument ? renderDocument() : renderNoDocument()}
    </div>
  );
};

export default InPageAnalytics;
