import React  from "react";
import { Container, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Footer from "./Footer";
import VerticalHeader from "./VerticalHeader";
import { useLocation } from "react-router-dom";
import HorizontalHeader from "./HorizonatalHeader";

const drawerWidth = 100;

const verticalUseStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    minHeight: '100vh',
    overflow: 'hidden',
  },
  sidebar: {
    width: drawerWidth,
    flexShrink: 0,
  },
  contentWrapper: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    paddingLeft: "32px",
    paddingTop: "32px",
  },
  content: {
    flexGrow: 1,
    marginTop: "32px",
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
  },
  gridItem: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexGrow: 1,
  },
  footer: {
    marginTop: 'auto',
  }
}));

const horizontalUseStyles = makeStyles(() => ({
  container: {
    marginTop: "3rem"
  },
  gridItem: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const HorizontalLayout: React.FC<CommonLayoutProps> = ({ children }) => {
  const styleClasses = horizontalUseStyles();
  return (
    <>
      <header>
        <HorizontalHeader/>
      </header>
      <main>
        <Container maxWidth="lg" className={styleClasses.container}>
          <Grid container>
            <Grid item xs={12}>
              {children}
            </Grid>
          </Grid>
        </Container>
      </main>
      <Footer />
    </>
  );
};

const VerticalLayout: React.FC<CommonLayoutProps> = ({ children }) => {
  const classes = verticalUseStyles();

  return (
    <div className={classes.root}>
      <nav className={classes.sidebar}>
        <VerticalHeader />
      </nav>
      <div className={classes.contentWrapper}>
        <main className={classes.content}>
          <Grid container className={classes.gridItem}>
            <Grid item xs={12}>
              {children}
            </Grid>
          </Grid>
        </main>
        <footer className={classes.footer}>
          <Footer />
        </footer>
      </div>
    </div>
  );
};


interface CommonLayoutProps {
  children: React.ReactNode;
}

const CommonLayout: React.FC<CommonLayoutProps> = ({ children }) => {
  const location = useLocation();
  const useOldLayout = location.pathname === "/shared";

  return useOldLayout ? (
    <HorizontalLayout>{children}</HorizontalLayout>
  ) : (
    <VerticalLayout>{children}</VerticalLayout>
  );
};

export default CommonLayout;
