import React, { useState, useEffect } from 'react';
import { Container, Box, Paper, Typography, CircularProgress } from '@mui/material';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { requestWrapper } from 'lib/api/auth';
import { BASE_URL } from 'lib/api/client';
import { useTranslation } from 'react-i18next';

interface TrendInsightProps {
  documentId: string | undefined;
}

const TrendInsight: React.FC<TrendInsightProps> = ({ documentId }) => {
  const [summary, setSummary] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const { t } = useTranslation("global");

  useEffect(() => {
    const fetchSummary = async () => {
      if (!documentId) {
        setError('Document ID is missing...');
        setLoading(false);
        return;
      }
      
      try {
        const flow_data = await requestWrapper(`${BASE_URL}/api/v1/flow_summary/trend_summary?docuId=${documentId}`);
        if (flow_data && flow_data["reply"]) {
          setSummary(flow_data["reply"]);
        } else {
          setError('要約が取得できませんでした...');
        }
      } catch (err) {
        setError('要約が取得できませんでした...');
      } finally {
        setLoading(false);
      }
    };

    fetchSummary();
  }, [documentId]);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Container maxWidth="sm">
        <Typography variant="h6" color="error">
          {error}
        </Typography>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg">
      <Box sx={{ mt: 4, mb: 2 }}>
        <Typography variant="h4" align="center" style={{ margin: 10, fontWeight: 'bold' }}>
          {t("mainSection.analytics-summary-tile")}
        </Typography>
      </Box>
      <Paper sx={{ p: 2 }}>
        <ReactMarkdown children={summary} remarkPlugins={[remarkGfm]} />
      </Paper>
    </Container>
  );
};

export default TrendInsight;
