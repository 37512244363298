import React, { useState, useEffect } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { requestWrapper } from 'lib/api/auth';
import { useNavigate } from 'react-router-dom';
import { BASE_URL } from 'lib/api/client';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2),
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      maxWidth: 400,
      margin: 'auto',
    },
    textField: {
      marginBottom: theme.spacing(2),
    },
    button: {
      marginTop: theme.spacing(2),
    },
  })
);

const UserProfilePage: React.FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [user, setUser] = useState({
    first_name: '',
    last_name: '',
    phone_number: '',
    bio: '',
  });

  useEffect(() => {
    const endpoint = `${BASE_URL}/api/v1/user_profile`;

    requestWrapper(endpoint)
      .then(data => {
        setUser(data);
      })
      .catch(error => {
        console.error('Error fetching user data:', error);
        if (error === "Authentication tokens are missing.") {
          // eslint-disable-next-line no-self-assign
          window.location.href = window.location.href;

        }
      });
  }, [navigate]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setUser((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    requestWrapper(`${BASE_URL}/api/v1/user_profile`, {method: "PUT", body: user})
      .then(data => {
        setUser(data);
      })
      .catch(error => {
        if (error === "Authentication tokens are missing.") {
          // eslint-disable-next-line no-self-assign
          window.location.href = window.location.href;
        } else {
        }
      });
  };

  return (
    <div className={classes.root}>
      <Typography variant="h4" gutterBottom>
        User Profile
      </Typography>
      <form className={classes.form} onSubmit={handleSubmit}>
        <TextField
          className={classes.textField}
          label="First Name"
          variant="outlined"
          name="first_name"
          value={user.first_name}
          onChange={handleChange}
        />
        <TextField
          className={classes.textField}
          label="Last Name"
          variant="outlined"
          name="last_name"
          value={user.last_name}
          onChange={handleChange}
        />
        <TextField
          className={classes.textField}
          label="Phone Number"
          variant="outlined"
          name="phone_number"
          value={user.phone_number}
          onChange={handleChange}
        />
        <TextField
          className={classes.textField}
          label="Bio"
          variant="outlined"
          name="bio"
          multiline
          minRows={4}
          value={user.bio}
          onChange={handleChange}
        />
        <Button
          className={classes.button}
          variant="contained" 
          color="primary"
          type="submit"
        >
          Update Profile
        </Button>
      </form>
    </div>
  );
};

export default UserProfilePage;
